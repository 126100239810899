@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.container {
		@apply mx-auto px-5;
	}

	.section-spacing {
		@apply pb-32;
	}

	.landing-top-spacing {
		@apply pt-28 lg:pt-36;
	}

	.section-title {
		@apply text-center mb-10;
	}

	.card {
		@apply bg-white shadow-md rounded-md p-8;
	}

	.nav-link {
		@apply font-display block w-fit text-sm font-medium uppercase hover:text-theme2 hover:opacity-75 duration-150 cursor-pointer px-2;
	}

	.nav-link.active {
		@apply text-theme2;
	}

	.button {
		@apply block w-fit rounded-md hover:shadow-lg active:scale-95 hover:opacity-90 transition duration-200 px-6 py-2;
	}

	.theme-button1 {
		@apply bg-theme1 text-white;
	}

	.theme-button2 {
		@apply bg-theme2 text-white;
	}

	.theme-button3 {
		@apply bg-theme3 text-white;
	}

	.pill {
		@apply rounded-full px-4 py-1;
	}

	.gradient-purple {
		@apply from-purple-400 via-purple-600 to-indigo-900;
	}

	.gradient-cyan {
		@apply from-cyan-600 via-cyan-700 to-cyan-900;
	}
}

@layer base {
	h1,
	h1 span,
	h1 p {
		@apply font-display text-4xl;
	}
	h2,
	h2 span,
	h2 p {
		@apply font-display text-3xl;
	}
	h3,
	h3 span,
	h3 p {
		@apply font-display text-2xl;
	}
	h4,
	h4 span,
	h4 p {
		@apply font-display text-xl;
	}
	h5,
	h5 span,
	h5 p {
		@apply font-display text-lg;
	}
	h6,
	h6 span,
	h6 p {
		@apply font-display;
	}

	p,
	span {
		@apply text-sm xl:text-[15px];
	}
}
