:root {
	--body: #f0f0f0;
	--theme1: #127295;
	--theme2: #5c2751;
	--theme3: #ccac8c;
	--theme4: #01003c;
}

* {
	font-family: "Wix Madefor Display", sans-serif;
}

body {
	background-color: var(--body);
	color: black;
	margin: 0;
}

.no-scroll {
	overflow: hidden;
}

.animation-pulse {
	animation: pulse 2s infinite 10s;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(18, 140, 126, 1);
	}

	40% {
		box-shadow: 0 0 0 10px rgba(18, 140, 126, 0);
	}
}
